<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports"/>
      </v-col>
    </v-row>
    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50
          "
        >

          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04) ">
                <p class="pa-4 font-extrabold text-xl text-blue" >Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row >
            <v-col cols="2">
              <v-autocomplete
                  v-model="searchParams.productTypeId"
                  outlined
                  background-color="#fff"
                  placeholder="Product Type"
                  item-value="id"
                  item-text="name"
                  :items="productTypes"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                  v-model="searchParams.venueServiceId"
                  outlined
                  background-color="#fff"
                  placeholder="Services"
                  item-value="id"
                  item-text="name"
                  return-object
                  :items="venueServices"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      outlined
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      hide-details
                      dense
                      v-model="date1Formatted"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.from_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      class="q-text-field shadow-0"
                      hide-details
                      dense
                      v-model="date2Formatted"
                      outlined
                      background-color="#fff"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.to_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                >Go</v-btn
                >
              </v-flex>
            </v-col>


          </v-row>


          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col>Expiring Packages Report </v-col>
                  <v-spacer></v-spacer>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="
                          checkExportPermission($modules.reports.service.slug)
                        "
                            class="export-button mt-2"
                            elevation="0"
                            height="40"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <SvgIcon text="Export Report" >
                            <template v-slot:icon>
                              <ExportIcon/>
                            </template>
                          </SvgIcon>
                        </v-btn>

                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>

            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table class="logTable">
                      <thead class="md-card-header shadow-0">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Customer Name
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="searchParams.productTypeId != 5"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Expiry Date</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Package Name
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Remaining Sessions
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Mobile</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Email</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody v-if="expiringPackageReport.length">
                        <template v-for="(data, lndex) in paginatedData">
                          <tr
                            class="md-table-row"
                            :key="`${lndex}`"
                            v-if="data.type == 'data'"
                          >
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{
                                  data.customer_name != null
                                    ? data.customer_name
                                    : "NA"
                                }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.productTypeId != 5"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ data.end_date | dateformat }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ data.products }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{
                                  data.remaining_sessions != null
                                    ? data.remaining_sessions
                                    : "--"
                                }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ data.mobile }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ data.email }}
                              </div>
                            </td>
                          </tr>
                          <tr
                            :key="`${lndex}`"
                            v-else
                            class="font-weight-black head_bg"
                          >
                            <td colspan="3">
                              <div
                                class="md-table-cell-container text-left pl-4"
                              >
                                {{ data.name != null ? data.name : "" }}
                              </div>
                            </td>
                            <td class="md-table-cell" colspan="3">
                              <div class="md-table-cell-container text-center">
                                Total Remaining Sessions --
                                {{
                                  data.remaining_sessions != null
                                    ? data.remaining_sessions
                                    : 0
                                }}
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="7">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <v-pagination
                      class="mt-3 new-pagination  "
                      v-model="page"
                      :length="totalPages"
                      total-visible="7"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
export default {
  components: {ExportIcon, SvgIcon, BackButton},
  data() {
    return {
      expiringPackageReport: [],
      services: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        productTypeId: 4,
        from_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      totalPages: 0,
      page: 1,
      paginatedData: [],
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    dateLength() {
      return this.expiringPackageReport.length;
    },
    rowSpan() {
      return parseInt(this.expiringPackageReport.length / 4);
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data.filter((item) =>
        ["Academy", "Membership", "Trainer"].includes(item.name)
      );
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes").then((res) => {
        if (res.status == 200) {
          this.searchParams.productTypeId = this.productTypes[0]
            ? this.productTypes[0].id
            : null;
        }
      });
    } else {
      this.searchParams.productTypeId = this.productTypes[0]
        ? this.productTypes[0].id
        : null;
    }
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/expiring-packages-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            this.expiringPackageReport = data;
            this.totalPages = Math.ceil(data.length / 10);
            this.getData();
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getData() {
      let limit = (this.page - 1) * 10;
      const allData = [...this.expiringPackageReport];
      let data = allData.splice(limit, 10);
      this.paginatedData = data;
    },
    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "expiring-packages-report-pdf";
      } else if (type == "excel") {
        link = "expiring-packages-report-excel";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.$http
        .get(`venues/reports/` + link + `${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Expiring-Packages-Report-" + this.fileName;
      else this.fileName = "Expiring-Packages-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function(opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}

.md-card-header{
  background-color: #edf7f8;
}
</style>
